import * as React from "react"
//import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Helmet>
      <link rel="stylesheet" type="text/css" href={withPrefix('sierpinski-master/source/assets/dependencies/data.gui.css')}/>
      <link rel="stylesheet" type="text/css" href={withPrefix('sierpinski-master/source/assets/css/index.css')}/>
      <script type="text/javascript" src={withPrefix('sierpinski-master/source/assets/dependencies/gl-matrix.js')}/>
      <script type="text/javascript" src={withPrefix('sierpinski-master/source/assets/dependencies/dat.gui.min.js')}/>
      <script type="text/javascript" src={withPrefix('sierpinski-master/source/assets/scripts/index.js')}/>
      <script type="text/javascript" src={withPrefix('test.js')}/>
    </Helmet>
        
    <section className="py-5 container">
      <div className="row py-lg-5">
        <div className="">
          <StaticImage
            src="../images/fractal-logo-email.png"
            width={300}
            quality={95}
            formats={["AUTO", "PNG"]}
            alt=""
            className="logo-fs img-fluid inline-block position-absolute bottom-0"
          />
          <h1 className="fw-light display-1 text-uppercase inline-block position-absolute bottom-0 ml-5">Fractal Solutions</h1>
          <p className="lead text-muted"></p>

        </div>
      </div>
      <div className="row">
      </div>
    </section>
  </Layout>
)

export default IndexPage
